/* Contact.css */
.contact {
  padding: 40px 20px;
  background-color: #f8f8f8;
  text-align: center;
  padding-bottom: 0px;
}

.contact h2 {
  font-size: 50px;
  font-family: "Cinzel", serif;
  color: #333;
  margin-bottom: 30px;
}

.contact-content {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 40px;
  max-width: 1200px;
  margin: 0 auto; /* Centers the content horizontally */
  flex-wrap: wrap;
}

.contact-info,
.contact-form {
  flex: 1;
  max-width: 500px;
  text-align: left;
  padding: 20px;
}

.contact-info h3 {
  font-size: 24px;
  font-family: "Cinzel", serif;
  color: #333;
  margin-bottom: 10px;
}

.contact-info p {
  font-size: 16px;
  color: #555;
  margin: 8px 0;
}

.contact-info a {
  color: #0073e6;
  text-decoration: none;
  font-weight: bold;
}

.contact-info a:hover {
  text-decoration: underline;
}

.socials {
  display: flex;
  justify-content: left; /* Center the icons */
  align-items: left;
  gap: 20px; /* Space between icons */
  margin-top: 20px; /* Space from the email */
}

.socials a {
  font-size: 32px; /* Icon size */
  color: black; /* Icon color */
  transition: color 0.3s ease; /* Smooth color transition */
  text-decoration: none; /* Remove underline */
}

.socials a:hover {
  color: #0073e6; /* Change color on hover (optional) */
}

.contact-form h3 {
  font-size: 24px;
  font-family: "Cinzel", serif;
  color: #333;
  margin-bottom: 20px;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  margin: 10px 0;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.contact-form button {
  min-width: 100%;
  padding: 12px;
  font-size: 18px;
  background-color: #333;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.contact-form button:hover {
  background-color: #555;
}

.footer-image {
  width: 100%;
  margin: -20px -20px 0px -20px;
  overflow: hidden; /* Ensures no overflow from the container */
}

.footer-image img {
  width: 100%; /* Ensures it spans the full width */
  height: 400px; /* Set the desired height (e.g., 200px) */
  object-fit: cover; /* Crops and scales the image to fit the container */
}
