/* Hero.css */
.hero {
    background: url('../assets/MainPage.JPG') no-repeat center center/cover; /* Correct image path */
    height: 95vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* Align content to the top */
    align-items: flex-start;     /* Align content to the left */
    text-align: left;            /* Align text to the left */
    padding: 80px 60px;          /* Move content down and inwards */
    color: white;
    position: relative;
  }
  
  .hero h1 {
    font-size: 80px;
    font-family: 'Cinzel', serif; /* Use Cinzel for headings */
    font-weight: 500;
    margin: 0;
    letter-spacing: 3px;
    text-transform: uppercase;
    line-height: 1.2;
  }
  
  .hero p {
    font-size: 28px;
    font-family: 'Playfair Display', serif; /* Use Playfair Display for paragraph text */
    font-weight: 500;
    margin: 15px 0 100px;
  }
  
  .hero button {
    background-color: transparent;
    border: 1px solid white;
    color: black;
    padding: 12px 36px;
    font-size: 16px;
    font-family: 'Playfair Display', serif; /* Use Playfair Display for button text */
    text-transform: uppercase;
    border-radius: 0;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;  
    letter-spacing: 2px;
    margin-top: 60px; /* Added margin-top to space out from the paragraph */
  }
  
  .hero button:hover {
    background-color: black;
    color: white;
  }
  