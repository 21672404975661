.categories {
  padding: 20px;
  text-align: center;
  background-color: #f9f9f9;
  min-height: 100vh;             /* Takes full height for better alignment */
  display: flex;
  justify-content: center;
}

.category-rotator {
  padding: 20px;
  background-color: #f8f8f8;
  max-width: 80vw;
  width: 100%;               /* Take full available width */
  min-height: 95vh;              /* Fixed height as requested */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 10px;
  overflow: hidden;
}

.category-title{
  vertical-align: top;
}

.category-rotator h3 {
  font-family: 'Cinzel', serif;
  font-size: 40px;
  margin-bottom: 0px; 
  color: #333;
}

.category-gallery {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  flex-grow: 1;              /* Distribute space evenly */
  align-items: center;
}

.category-image {
  width: calc(50% - 20px);   /* Increase the width for larger images */
  height: 45vh;              /* Set a larger fixed height */
  max-width: 31%;          /* Optional: Limit max width */
  overflow: hidden;
  border-radius: 8px;
  display: flex;             /* Center images in container */
  justify-content: center;
  align-items: center;
}

.category-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.category-image img:hover {
  transform: scale(1.05);    /* Subtle zoom effect */
}

