/* App.css */
body {
  margin: 0;
  font-family: 'Lato', sans-serif;
  background-color: #fafafa;  /* Light beige/white */
  color: #3a3a3a;
}

h1, h2 {
  font-weight: 700;
  margin: 20px 0;
}

button {
  background-color: #6b4f3e;  /* Rich brown */
  color: white;
  border: none;
  padding: 12px 30px;
  font-size: 18px;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #594334;  /* Darker brown on hover */
}
