.biography {
  padding: 60px 20px;
  background-color: #fff;
  text-align: center;
}

.biography h2 {
  font-family: 'Cinzel', serif;
  font-size: 50px;
  margin-bottom: 30px;
}

.bio-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.bio-image img {
  width: auto; /* Maintain the original aspect ratio */
  height: auto; /* Ensure no distortion */
  max-width: 100%; /* Fit within the container */
  max-height: 50vh; /* Adjust maximum height as needed */
  border-radius: 10%;
  margin-bottom: 30px;
}

.bio-text {
  font-family: 'Playfair Display', serif;
  font-size: 20px;
  line-height: 1.6;
  max-width: 800px;
  margin: 0 auto;
}
