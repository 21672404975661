/* Testimonials.css */
.testimonials {
    padding: 60px 20px;
    background-color: #f9f9f9;
    text-align: center;
  }
  
  .testimonials h2 {
    font-family: 'Cinzel', serif;
    font-size: 50px;
    margin-bottom: 40px;
  }
  
  .testimonial-cards {
    display: flex;
    justify-content: center;
    gap: 30px;
    flex-wrap: wrap;
  }
  
  .testimonial-card {
    background-color: white;
    padding: 20px;
    width: 300px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .testimonial-card p {
    font-family: 'Playfair Display', serif;
    font-size: 18px;
    font-style: italic;
    margin-bottom: 10px;
  }
  
  .testimonial-card h4 {
    font-family: 'Cinzel', serif;
    font-size: 20px;
    font-weight: 600;
  }
  